const NEW_COMMENT = 'New comment';
const NEW_BRIEF_SUBMITTED = 'New project submitted'
const STATUS_UPDATE = 'Status update';

const NOTIFICATION_TITLE = {
    NEW_COMMENT,
    NEW_BRIEF_SUBMITTED,
    STATUS_UPDATE
}

export default NOTIFICATION_TITLE