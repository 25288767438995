import axios from '@/utils/axios'
// import pick from 'lodash/pick'
// import Vue from 'vue';

const DEFAULT_STATE = () => ({
    data: [],
    total: 0,
    client: null,
    feeds: null,
    loading: false,
})

const ACTIONS = {
    async createClient(ctx, payload) {
        try {
            ctx.commit('setLoading', true)

            const response = await axios.request({
                url: 'client/create',
                method: 'POST',
                data: payload,
            })

            ctx.commit('setLoading', false)
            return response
        } catch(err) {
            // keep quite
        }
    },

    async getClientList(ctx, payload) {
        try {
            ctx.commit('setLoading', true)
            const response = await axios.request({
                url: `/base/client_details?${payload}`,
                method: 'GET',
            })

            ctx.commit('setListData', response)
            ctx.commit('setLoading', false)
            return response
        } catch(err) {
            // keep quite
        }
    },

    async getClientData(ctx, payload) {
        try {
            ctx.commit('setLoading', true)
            const response = await axios.request({
                url: `/base/client_details/${payload}`,
                method: 'GET',
            })

            ctx.commit('setClient', response)
            ctx.commit('setLoading', false)
            return response
        } catch(err) {
            return err
        }
    },
    async getClients(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/client/pull-client?${payload.params}`,
				method: 'GET',
			})
            console.log("PULL CLIENT",response);
			ctx.commit('setClientListData', response)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			return Promise.reject(err)
		}
	},

    async getAllClientListData(ctx, payload) {
        try {
            ctx.commit('setLoading', true)
            const response = await axios.request({
                url: `/base/client_details?${payload}`,
                method: 'GET',
            })
            ctx.commit('setLoading', false)
            ctx.commit('setClientListData', response)
            return response
        } catch(err) {
            return err
        }
    },
    async getClientCustomQuestions(ctx, payload) {
        try {
            ctx.commit('setLoading', true)
            const response = await axios.request({
				url: `/client/${payload}/custom_question`,
				method: 'GET',
			})
            ctx.commit('setLoading', false)
            ctx.commit('setCustomQuestion', response)
            return response
        } catch(err) {
            return err
        }
    },

    async updateClient(ctx, payload) {
        try {
            ctx.commit('setLoading', true)

            const response = await axios.request({
                url: `base/client/${payload.id}`,
                method: 'PUT',
                data: payload,
            })

            ctx.commit('setLoading', false)
            return response
        } catch(err) {
            // keep quite
        }
    },

    async deleteClient(ctx, payload) {
        try {
            ctx.commit('setLoading', true)

            const response = await axios.request({
                url: `base/client/${payload.id}`,
                method: 'DELETE',
                data: payload,
            })

            ctx.commit('setLoading', false)
            return response
        } catch(err) {
            // keep quite
        }
    },
    async getClientMembers(ctx, payload) {
        try {
            ctx.commit('setLoading', true)

            const response = await axios.request({
                url: `client/members?id=${payload}`,
                method: 'GET',
            })
            
            ctx.commit('setLoading', false)
            return response
        } catch(err) {
            // keep quite
        }
    },

    async getFeeds(ctx, payload) {
        try {
            const { role, client_id } = payload
            console.log('feed role', role)
            let urlParam = (role != 'Admin') ? `base/feeds?client_id=${client_id}` : `base/feeds`;
            let response = await axios.request({
                url: urlParam,
                method: 'GET',
            })
            let data = [];
            // filter relevant feeds based on role
            if (role != 'Admin') {
                const { request_ids } = payload
                const projectInvolvedFeeds = request_ids.flatMap(reqID => [...response.filter(v => v.link.includes(reqID))])
                projectInvolvedFeeds?.sort(function(a,b){
                    return new Date(b.created_date) - new Date(a.created_date);
                  });
                response = projectInvolvedFeeds
            }

            data = response.slice(0, 5)
            console.log('data',data);
            ctx.commit('setFeedsData', data);
            return data
        } catch(err) {
            // keep quite
        }
    }
}

const MUTATIONS = {
    setListData(state, payload) {
        state.data = payload.data
        state.total = payload.count
    },
    setClientListData(state, payload) {
        state.data = payload
    },
    setFeedsData(state, payload){
        state.feeds = payload
    },
    setClient(state, payload) {
    state.client = payload
    },
    setLoading(state, payload) {
    state.loading = payload
    },
    setCustomQuestion(state, payload) {
		state.custom_questions = payload
	},
    clearAuth(state) {
        Object.assign(state, DEFAULT_STATE() )
    }
}

const GETTERS = {
    feeds_DATA: state => {
		return state.feeds
	},
    /*
        shipments: state => state.shipments,
        shipmentsCount: state => state.shipments.length,
        modified: state => state.modified,
        loading: state => state.loading,
        updated: state => state.updated,
        data: state => state.data,
        filteredShipment(state, getters) {
            if (!getters.shipmentsCount)
                return []

            if (state.keyword)
                return state.shipments
                    .filter(({ name }) => name.toLowerCase().includes(state.keyword.toLowerCase()))

            return state.shipments
        }
    */
}

const CLIENT = {
    namespaced: true,
  state: DEFAULT_STATE() ,
  actions:ACTIONS,
  mutations: MUTATIONS,
    getters: GETTERS
}

export default CLIENT