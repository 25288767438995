<template>
  <div class=" shadow comment-container mt-4">
    <div class="card-header bg-transparent">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">Recent Activity</h5>
        </div>
      </div>
       <br>
    </div>

    <!-- <loader :loading="loading" class="mt-4"/> -->    
    <div class="card-body " style="padding:0.5rem;font-size: 12px;">
          <div class="feed-messages-container activity file-container no-height" style="margin-right:0 !important">
            <div class="feed-message" v-for="(row, index) in feeds" :key="index" style="cursor: pointer;padding:1.0rem">
              <div class="feed-user justify-content-between" @click="openLink(row)">
                <div class="d-flex">
                  <div class="feed-user-details" >
                      <div class="feed-name">{{row.message}}</div>                      
                      <div class="text-muted feed-date" >{{$moment(row.created_date).fromNow()}}</div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      
    </div>
    <modal :show="isFeedOpen" @close="openFeed" modalClasses="modal-xl full-screen-modal" style="z-index:10000">
      <h6 slot="header" class="modal-title" /> 
         
            <iframe 
              class="col-lg-12 col-md-12"
              height="100%"
              :src="source"
            
            allowfullscreen
            style="pointer-events: none;" id="myFrame"
          />
        
    </modal>
  </div>
  
</template>
<script>


//import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import { mapState } from 'vuex';

export default {
  name: 'feed-section',
  props: {
    isNew: {
      type: Boolean,
      description: 'to determine if new request or not',
    }
  },
  data () {
    return{
      myIframe: null,
      userData: null,
      source: '',
      isLinkOpen: false,
      isFeedOpen: false,
      table: {
        sort: [
          {
            column: 'created_date',
            direction: 'desc'
          }
        ],
        client_id: this?.clientId,
        page: 0,
        size: 5,
        sizeOptions: [
          5, 10, 20, 50
        ],
        
      },
    }
  },
  methods: {
    openLink(row) {
            this.source = row.link;
           // this.isFeedOpen = !this.isFeedOpen;
          
           return window.open(row.link,'_self')
           
          },
    openFeed(){
      this.isFeedOpen = !this.isFeedOpen;
    },
   
     async pullFeeds() {   
      await this.$store.dispatch('CLIENT/getFeeds', this.userData)
    },
  },
  async created() {
    this.userData = await this.$store.dispatch('USER/getUserDataByEmailNoCommit', this.user.email);
    if (!this.userData) {
      console.log('unable to load feeds.. user data null')
      return
    }
    
    await this.pullFeeds();
   
    const interval = setInterval(async () => {
       await this.pullFeeds();
       if (!this.user) {
          clearInterval(interval)
       }
      },60 * 1000)

    this.$root.$refs.feedsComponent = this;
  },
  
  computed: {
    ...mapState('CLIENT', {
      feeds: state => state.feeds,
    }),     
    ...mapState('AUTH', {
      user: state => state.user,
    }),
    clientId() {
      if(this.user.role == 'Admin'){
        return 'Admin'
      }
      return this.user?.client?.id;
    },
    startCase: () => startCase,
  }
};
</script>

<style scoped>
.edit-comment > a {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;

  box-shadow: none;
  background: transparent;

  border-radius: 100%;
}

.feed-message:hover {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #F4F5F7 !important;
}

.highlights__content {
  position: relative;
}
 
/* .highlights__placeholder {
  color: #ccc;
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: -1;
} */
 
.highlights__body-container {
  border-radius: 5px;
  border: 1px solid #eaeaea;
  padding: 16px;
}
 
.highlights__body {
  min-height: 60px;
}
 
/* .highlights {
  color: #ff3b8e;
} */

 
/* .highlight {
  animation: blink 3s ease;
} */

.sort {
  height: 25px;
  width: 25px;
}
.feedsmodal{
  pointer-events: none;
}

/* 
@keyframes blink {
  0% {
    background-color: yellow;
  }
  100% {
    background-color: white;
  }
} */

/* .editor-highlight {
   background-image: linear-gradient(45deg, yellow, white);
} */

</style>
