import Vue from 'vue'
import Vuex from 'vuex'

import AUTH from '@/store/auth'
import REQUEST from '@/store/request'
import QUESTION from '@/store/question'
import CUSTOMQUESTION from '@/store/custom_question'
import TEMPLATE from '@/store/template'
import CLIENT from '@/store/client'
import USER from '@/store/user'
import DOCUMENT from '@/store/document'
import INVITE from '@/store/invite'
import KNOWLEDGE_BASE from '@/store/knowledge_base'

Vue.use(Vuex)

const store = new Vuex.Store({
	strict: true,
	modules: {
		USER,
		AUTH,
		REQUEST,
		QUESTION,
		CUSTOMQUESTION,
		CLIENT,
		DOCUMENT,
		INVITE,
		TEMPLATE,
		KNOWLEDGE_BASE
	},
	state: {},
	mutations: {},
	actions: {},
	getters: {},


})


// store.subscribe((mutation, state) => {
// 	console.log('subscribe type: ',mutation.type)
// 	console.log('subscribe payload: ',mutation.payload)
// 	console.log('subscribe state: ', state)
// })


export default store

