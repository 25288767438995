<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }" style="z-index:2">
    <side-bar
      :background-color="sidebarBackground"
    >
      <template slot="links">
         <sidebar-item
            v-for="(item, index) in getRoutes"
            :key="index"
            :link="item"
        >
          <template slot="icon" v-if="!item.icon">  
            <img v-if="isActive(item.name)" :src="item.activeIcon" class="image-icon" />
            <img v-if="!isActive(item.name)" :src="item.defaultIcon" class="image-icon" />
          </template>
          
        </sidebar-item>
        <feed-section v-if="true">  </feed-section>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      
      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
    
  </div>
</template>
<script>
    import DashboardNavbar from './DashboardNavbar.vue';
     import feedSection from '@/container/feed/FeedContainer.vue';
    import ContentFooter from './ContentFooter.vue';
    import { FadeTransition } from 'vue2-transitions';
    import { mapGetters, mapState} from "vuex";
    import NOTIFICATION_TITLE from '@/utils/constant'

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition,
      feedSection
    },
    data() {
      return {
        sidebarBackground: 'orange', //vue|blue|orange|green|red|primary
        routes: [
          {
            name: 'Dashboard',
            path: '/request',
            defaultIcon: '/img/icons/common/request.svg',
            activeIcon: '/img/icons/common/request-active.svg',
            role: ['Admin', 'Editor', 'Creative Director']
          },
          {
            name: 'Dashboard',
            path: '/request',
            defaultIcon: '/img/icons/common/request.svg',
            activeIcon: '/img/icons/common/request-active.svg',
            role: ['User']
          },
          {
            name: 'Clients',
            path: '/client',
            defaultIcon: '/img/icons/common/clients.svg',
            activeIcon: '/img/icons/common/clients-active.svg',
            role: ['Admin', 'Editor', 'Creative Director']
          },
          {
            name: 'User',
            path: '/user',
            defaultIcon: '/img/icons/common/team.svg',
            activeIcon: '/img/icons/common/team-active.svg',
            role: ['Admin']
          },
          // {
          //   name: 'Questions',
          //   path: '/question',
          //   icon: 'ni ni-collection',
          //   role: ['Admin']
          // },
          //  {
          //   name: 'Custom Questions',
          //   path: '/custom-question',
          //   icon: 'ni ni-collection',
          //   role: ['Admin']
          // },
          {
            name: 'User Invite',
            path: '/user-invite',
            icon: 'fa fa-feather',
            role: ['Admin']
          },
          {
            name: 'Knowledge Base',
            path: '/knowledge-base',
            icon: 'fa fa-info-circle',
            role: ['Admin', 'User', 'Editor', 'Creative Director']
          },
         
          // {
          //   name: 'Settings',
          //   path: '/profile',
          //   defaultIcon: '/img/icons/common/settings.svg',
          //   activeIcon: '/img/icons/common/settings-active.svg',
          //   role: ['Admin', 'Editor', 'Creative Director', 'User']
          // },
        ],
      };
    },
    methods: {
      isActive(item) {
        return this.route === (this.$lodash.lowerCase(item))
      },
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      async wsConnect() {
        const encryptedUserId = this.CryptoJS.AES.encrypt(this.userId, process.env.VUE_APP_WS_SECRET).toString()

        this.$connect(`${process.env.VUE_APP_WEBSOCKET_URL}?token=${encryptedUserId}`)
        this.$options.sockets.onopen = data => {
          if(data) {
            console.log('Connection to server is a success');
          }else{
            console.log('Connection to server is not a success');
            console.log(data);
          }
        };
      },
      notify(title, message){
        this.$notify({
          timeout: 3000,
          icon: 'ni ni-check-bold',
          type: 'success',
          title: title,
          message: message
        })
      },
   
      highlightUpdate(){
        const intervalTime = (this.$route?.hash && this.$route.fullPath.includes('user-invite')) ? 500 : 2000
        const interval = setInterval(async () => {
          if(this.$refs) {      
              if(this.$route?.hash) {
                  let self = this
                  const refName = self.$route?.hash.split('#').pop()
                   console.log('refName',refName)
                  
                 //componentRef.highlight = true;
                  if('status-update' == refName) {
                      this.$root.$refs.statusSection.statusHighlight = true;
                      const componentRef = Array.isArray(this.$root.$refs.statusSection.$refs[refName]) ? this.$root.$refs.statusSection.$refs[refName][0] : this.$root.$refs.statusSection.$refs[refName]  
                                   
                      componentRef.scrollIntoView({ behavior: 'smooth',
                      block: 'center',
                      inline: 'nearest'})
                      clearInterval(interval)
                  }else if('collaborators' == refName){
                    this.$root.$refs.Collaborator.colabHighlight = true;                   
                    const componentRef = Array.isArray(this.$root.$refs.Collaborator.$refs[refName]) ? this.$root.$refs.Collaborator.$refs[refName][0] : this.$root.$refs.Collaborator.$refs[refName]  
                    componentRef.scrollIntoView({ behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest'})
                    clearInterval(interval)
                  }else if('uploaded' == refName){
                    this.$root.$refs.Delivarable.uploadHighlight = true;                   
                    const componentRef = Array.isArray(this.$root.$refs.Delivarable.$refs[refName]) ? this.$root.$refs.Delivarable.$refs[refName][0] : this.$root.$refs.Delivarable.$refs[refName]  
                    componentRef.scrollIntoView({ behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest'})
                    clearInterval(interval)
                  }else{
                    const isDeliverable = refName.includes('deliverable')
                    if (isDeliverable) {
                      if (!this.$root.$refs.deliverableSection) {
                        return
                      }

                      const deliverableID = refName
                      this.$root.$refs.deliverableSection.deliverableHighlight = deliverableID                
                      const componentRef = Array.isArray(this.$root.$refs.deliverableSection.$refs[deliverableID]) ? this.$root.$refs.deliverableSection.$refs[deliverableID][0] : this.$root.$refs.deliverableSection.$refs[deliverableID]  

                      if (!componentRef) {
                        this.$root.$refs.deliverableSection.table.size = 250
                        await this.$root.$refs.deliverableSection.handleTableChange()
                        
                        return
                      }

                      componentRef.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                          inline: 'nearest'
                      })
                      clearInterval(interval)

                      return
                    }

                    if (self.$route.fullPath.includes('user-invite')) {
                      clearInterval(interval)
                      const invite = await this.$root.$refs.userInviteComponent.getInvitationDetails(refName);
                      this.$root.$refs.userInviteComponent.handleShowDetails(invite)
                      return
                    }

                    this.$root.$refs.A.commentHighlight = refName;
                    const componentRef = Array.isArray(this.$root.$refs.A.$refs[refName]) ? this.$root.$refs.A.$refs[refName][0] : this.$root.$refs.A.$refs[refName]  
                                   
                    componentRef.scrollIntoView({ behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest'})
                    clearInterval(interval)
                  }
              } else {
                clearInterval(interval)
              }
          }
    
      }, intervalTime)
      }
    },
    mounted(){
      const app_settings = this.settings.app_settings
      //this.wsConnect();

      this.$options.sockets.onmessage = (data) => {
        if(app_settings.in_app_notification) {
          const message = JSON.parse(data.data);

          if(message.recipient == this.userId) {

            if(app_settings.mentions_only) {
              if(message.title == NOTIFICATION_TITLE.NEW_BRIEF_SUBMITTED || message.message.includes('mentioned')) {
                  this.notify(message.title, message.message)
              }
            } else {
              if(app_settings.activity_updates) {
                this.notify(message.title, message.message)
              }
            }

          }
        }
      }
     this.highlightUpdate();

      
    },
    computed: {
        ...mapGetters("AUTH", [
            'userRole',
            'userId'
        ]),
      ...mapState('AUTH', {
        settings: state => state.app_settings,
      }),
      route() {
        return this.$route.name
      },

      getRoutes() {
        return this.routes.filter(route => {
            return route.role.includes(this.userRole)
        })
      }
    },
  };
</script>
<style lang="scss">

.highlights {
  color: #ff3b8e;
}

 
.highlight {
  animation: blink 5s ease;
}

.sort {
  height: 25px;
  width: 25px;
}


@keyframes blink {
  0% {
    background-color: #8F64D9;
  }
  100% {
    background-color: white;
  }
}
</style>
