import axios from '@/utils/axios'
// import pick from 'lodash/pick'

const DEFAULT_STATE = () => ({
	data: [],
	total: 0,
	question: null,
	loading: false,
})

const ACTIONS = {
	async createCustomQuestion(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: 'base/custom_question',
				method: 'POST',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},

	async getCustomQuestionList(ctx, payload) {
		console.log("CustomQuestionPayload",payload)
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/base/custom_question_details?${payload}`,
				method: 'GET',
			})

			ctx.commit('setListData', response)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},
	

	async getQuestionData(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/base/question_details/${payload}`,
				method: 'GET',
			})

			ctx.commit('setQuestion', response)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			return Promise.reject(err)
		}
	},

	async updateCustomQuestion(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `base/templates_question/${payload.id}`,
				method: 'PUT',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},

	async deleteCustomQuestion(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `base/templates_question/${payload.id}`,
				method: 'DELETE',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},

}

const MUTATIONS = {
	setListData(state, payload) {
    state.data = payload.data
    state.total = payload.count
	},
	setQuestion(state, payload) {
    state.question = payload
	},
	setLoading(state, payload) {
    state.loading = payload
	},
	clearAuth(state) {
		Object.assign(state, DEFAULT_STATE() )
	}
}

const GETTERS = {
	/*
		shipments: state => state.shipments,
		shipmentsCount: state => state.shipments.length,
		modified: state => state.modified,
		loading: state => state.loading,
		updated: state => state.updated,
		data: state => state.data,
		filteredShipment(state, getters) {
			if (!getters.shipmentsCount)
				return []

			if (state.keyword)
				return state.shipments
					.filter(({ name }) => name.toLowerCase().includes(state.keyword.toLowerCase()))

			return state.shipments
		}
	*/
}

const CUSTOMQUESTION = {
	namespaced: true,
  state: DEFAULT_STATE() ,
  actions:ACTIONS,
  mutations: MUTATIONS,
	getters: GETTERS
}

export default CUSTOMQUESTION