import axios from '@/utils/axios'
import values from 'lodash/values'
import keyBy from 'lodash/keyBy'
import cloneDeep from 'lodash/cloneDeep'
// import findIndex from 'lodash/findIndex'
import orderBy from 'lodash/orderBy'

const DEFAULT_STATE = () => ({
    data: [],
    total: 0,
    document: null,
    loading: false,
    documents: {},
    directory: '',
})

const ACTIONS = {
    async uploadDocument(ctx, payload) {
        try {
            ctx.commit('setLoading', true)
            console.log('Payload');
            console.log(payload);
            const response = await axios.request({
                url: '/drive/upload',
                method: 'POST',
                data: payload,
            })
            console.log("uploading 2123");
            console.log(payload);
            ctx.commit('setLoading', false)
            return response
        } catch(err) {
            // keep quite
        }
    },
    async getS3AssetList(ctx, resourceId){
        try {
            ctx.commit('setLoading', true)
            const response = await axios.request({
                url: `/s3/asset-list?resource_id=${resourceId}`,
                method: 'GET'
            })

            if(response){
                const contents = response.result.Contents
                const s3_assets = response.s3
                let dataList = []
                contents.map(async (content) => {
                    let key = content.Key.split('/')
                    let directory = ''
                    
                    for (let index = 1; index < key.length; index++) {
                        const keyName = key[index];
                        const isFile = keyName.includes('.')
                        let contentSize = content.Size;
                        let isFolder = false
                     
                        if(keyName.length > 0){
                            if(index <= 1) {
                                directory = key[0]
                            }

                            if(!isFile){
                                isFolder = true
                                contentSize = 0
                            } else {
                                isFolder = false
                            }

                            const found = dataList.some(({ Directory, Key}) => Directory === directory && Key === keyName)
                            if(!found){

                                let uploader = null;
                                s3_assets.forEach(s3 => {
                                    if(s3.key === content.Key) {
                                        uploader = s3
                                    }
                                });

                                let data = {
                                    Key:  keyName,
                                    Size: contentSize,
                                    isFolder: isFolder,
                                    Directory: directory,
                                    uploader: uploader
                                }
                                dataList.push(data)
                            }
                            directory = `${directory}/${keyName}`
                            
                        }
                    }
                })
                
                ctx.commit('setListData', dataList)
                ctx.commit('setLoading', false)
                return dataList
            }
        } catch (err) {
            console.log('err: ', err)
        }
    },
    async getS3SignedUrl(ctx, key){
        try {
            ctx.commit('setLoading', true)
            const response = await axios.request({
                url: `/s3/asset-object?key=${key}`,
                method: 'GET'
            })
            ctx.commit('setLoading', false)
            return response.result
        } catch (err) {
            console.log('err: ', err)
        }
    },
    async createS3Folder(ctx, key){
        try {
            ctx.commit('setLoading', true)
            const response = await axios.request({
                url: `/s3/create-directory?key=${key}/`,
                method: 'POST'
            })
             
            if(response){
                let folder = key.split('/')
                let directory = ''
                let keyName = folder[folder.length - 1]
              
                for (let index = 0; index < folder.length - 1; index++) {
                    if(directory.length > 0) {
                        directory = `${directory}/${folder[index]}`
                    } else {
                        directory = folder[index]
                    }
                }

                let data = {
                    Key:  keyName,
                    Size: 0,
                    LastModified: '',
                    ETag: '',
                    isFolder: true,
                    Directory: directory
                }
                let dataList = ctx.state.data
                dataList.push(data)
                ctx.commit('setListData', dataList)
            }
            ctx.commit('setLoading', false)
            return response
        } catch (err) {
            console.log('err: ', err)
        }
    },
    async deleteS3Object(ctx, key){
        try {
            ctx.commit('setLoading', true)
            const response = await axios.request({
                url: `/s3/delete-object?key=${key}`,
                method: 'POST'
            })

            if(response){
                let dataList = ctx.state.data
                let removeKey = key.split('/').pop()             
                let filtered = dataList.filter(({ Key }) => Key !== removeKey)   
                ctx.commit('setListData', filtered)
            }
            ctx.commit('setLoading', false)
        } catch (err) {
            console.log('err: ', err)
        }
    },
    async deleteS3MultipleObject(ctx, key){
        try {
            ctx.commit('setLoading', true)
            const list = await axios.request({
                url: `/s3/asset-list?resource_id=${key}/`,
                method: 'GET'
            })

            if(list){
                const contents = list.result.Contents
                let objectsToDelete = []
                contents.map((content) => {
                    objectsToDelete.push({
                        Key: content.Key
                    })
                })
                
                const response =  await axios.request({
                    url: `/s3/delete-multiple`,
                    method: 'POST',
                    data: objectsToDelete
                })

                if(response){
                    let dataList = ctx.state.data
                    let objects = response.result.data.Delete.Objects
                    objects.map((obj) => {
                        let removeKey = obj.Key.split('/')
                        console.log(removeKey)
                        let counter = removeKey.length - 1
                        let keyName = removeKey[counter]

                        if(keyName.length <= 0) {
                            counter = removeKey.length - 2
                            keyName = removeKey[counter]
                        }

                        dataList = dataList.filter(function(k) {
                            return k.Key !== keyName
                        })
                    })
                    ctx.commit('setListData', dataList)
                }
                
            }
            ctx.commit('setLoading', false)
        } catch (err) {
            console.log('err: ', err)
        }
    },
}

const MUTATIONS = {
    setListData(state, payload) {
        state.data = payload
        state.total = payload
    },
    setLoading(state, payload) {
        state.loading = payload
    },
    setDocuments(state, payload) {
        state.documents = keyBy(orderBy(values({ ...state.documents, ...payload }), 'id'), 'id')
    },
    removeDocument(state, payload) {
        const { fileId, client_id, resource } = payload
        const documents = values(cloneDeep(state.documents))

        const filtered = documents?.filter(doc => {
            const r = resource && (resource && doc?.resource !== resource)
            const c = client_id && (client_id && doc?.client_id !== client_id)
            return fileId
                ? doc?.id !== fileId
                : r && c
        })

        return state.documents = keyBy(orderBy(filtered, 'id'), 'id')
    },
    clearState(state) {
        Object.assign(state, DEFAULT_STATE() )
    },
    setDirectory(state, payload){
        state.directory = payload
    },
}

const GETTERS = {
    loading: state => state.loading,
    documents: state => state.documents,
    directory: state => state.directory,
    data: state => state.data
}

const DOCUMENT = {
    namespaced: true,
    state: DEFAULT_STATE(),
    actions:ACTIONS,
    mutations: MUTATIONS,
    getters: GETTERS,
    // subscribe((mutation, state)  {
      // console.log(mutation.type)
      // console.log(mutation.payload)
    // })
}

export default DOCUMENT