<template>
    <base-nav class="navbar-top navbar-dark" id="navbar-main" :show-toggle-button="false" :title="title()" :base64Image="client_photo" expand>
        
        <ul class="navbar-nav align-items-center d-none d-md-flex ml-lg-auto">
            <li class="nav-item dropdown">
                <base-dropdown class="nav-link pr-0">
                    <div class="media align-items-center" slot="title">
                        <span class="avatar avatar-sm rounded-circle bg-success" role="button" :class="{'mr-5' : (username.length < 11)}">
                            <img alt="Image placeholder" :src="user_photo"  v-if="user_photo" />
                            <span v-if="!user_photo">{{ $tools.getInitial(username) }}</span>
                        </span>
                        <div class="media-body ml-2 d-none d-lg-block">
                            <span class="mb-0 text-sm font-weight-bold" role="button">{{ username }}</span>
                        </div>
                    </div>

                    <template>
                        <div class="dropdown-header noti-title">
                            <h6 class="text-overflow m-0">Welcome!</h6>
                        </div>
                         <div class="dropdown-item" @click.prevent="handleNavigateProfile">
                            <i class="ni ni-single-02"></i>
                            <span>My profile</span>
                        </div>
                         <div class="dropdown-item" @click.prevent="handleRSettings">
                            <i class="ni ni-settings-gear-65"></i>
                            <span>Notification settings</span>
                        </div>
                        <div class="dropdown-item" @click.prevent="handleResetPasword">
                            <i class="ni ni-lock-circle-open"></i>
                            <span>Reset password</span>
                        </div>
                        <div class="dropdown-divider"></div>
                        <router-link to="/logout" class="dropdown-item">
                            <i class="ni ni-user-run"></i>
                            <span>Logout</span>
                        </router-link>
                    </template>
                </base-dropdown>
            </li>
        </ul>
    </base-nav>
</template>
<script>
    import { mapState } from "vuex"

    export default {
        data() {
            return {
                activeNotifications: false,
                showMenu: false,
                searchQuery: "",
                base64Image: "",
                userPhoto: "",
                overrideWidth : false,
            };
        },
        computed: {
            ...mapState('AUTH', {
                user: state => state.user,
                client: state => state.client
            }),
            ...mapState('USER', {
                userData: state => state.user,
            }),
            user_photo() {
                return this.user?.profile_image
            },
            client_photo() {
                return this.user?.client?.profile_image || this.base64Image
            },
            username() {
                if (!this.user)
                    return ''

                const fname = this.$lodash.upperFirst(this.user?.first_name)
                const lname = this.$lodash.upperFirst(this.user?.last_name)
                
                return `${fname} ${lname}`
            }

        },
        watch: {
            userData() {
                console.log('refreshed')
            }
        },
        methods: {
            toggleSidebar() {
                this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
            },
            hideSidebar() {
                this.$sidebar.displaySidebar(false);
            },
            toggleMenu() {
                this.showMenu = !this.showMenu;
            },
            handleNavigateProfile() {
                this.$router.push(`/user/${this?.user?.id}`)
            },
            handleRSettings(){
                this.$router.push(`/settings`)
            },
            async handleResetPasword() {
                const request = await this.$store
                    .dispatch('AUTH/requestForgotPassword', {
                        email: this?.user?.email
                    })

                if (request) {
                  this.$notify({
                    timeout: 10000,
                    icon: 'ni ni-check-bold',
                    type: 'success',
                    title: 'Password Reset Sent',
                    message: `Sucessfuly sent`
                  })

                  this.$router.push({name: 'login'})
                } else {
                  this.$notify({
                    icon: 'fa fa-times',
                    type: 'danger',
                    title: 'Error',
                    message: `Something went wrong!`
                  })
                }
            },
            title() {
                if(this.user?.role == 'User') {
                    return this.user?.client?.name;
                }
                return 'Dashboard'
            },
            
        },
    };
</script>
