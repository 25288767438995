
import clickOutside from "../directives/click-ouside.js";
// import clickCtrl from "../directives/click-ctrl.js";
/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive("click-outside", clickOutside);
    // Vue.directive("click-ctrl", clickCtrl);
  }
};

export default GlobalDirectives;
