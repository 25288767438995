<template>
    <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main" style="z-index:3">
        <div class="container-fluid">

            <!--Toggler-->
            <navbar-toggle-button @click.native="showSidebar">
                <span class="navbar-toggler-icon"></span>
            </navbar-toggle-button>
            <router-link class="navbar-brand" to="/">
                <img :src="logo" class="navbar-brand-img" alt="...">
            </router-link>

            <slot name="mobile-right">
                <ul class="nav align-items-center d-md-none">
                    <base-dropdown class="nav-item" position="right">
                        <a slot="title" class="nav-link" href="#" role="button">
                            <div class="media align-items-center">
                                <span class="avatar avatar-sm rounded-circle bg-success">
                                    <img alt="Image placeholder" :src="user_photo" v-if="user_photo"/>
                                    <span v-if="!user_photo">{{ $tools.getInitial(username) }}</span>
                                </span>
                                <div class="media-body ml-2 d-none d-lg-block" role="button">
                                    <span class="mb-0 text-sm font-weight-bold">{{ username }}</span>
                                </div>
                            </div>
                        </a>

                        <div class=" dropdown-header noti-title">
                            <h6 class="text-overflow m-0">Welcome!</h6>
                        </div>
                        <div class="dropdown-item" @click.prevent="handleNavigateProfile">
                            <i class="ni ni-single-02"></i>
                            <span>My profile</span>
                        </div>
                          <div class="dropdown-item" @click.prevent="handleRSettings">
                            <i class="ni ni-settings-gear-65"></i>
                            <span>Notification settings</span>
                        </div>
                        <div class="dropdown-item" @click.prevent="handleResetPasword">
                            <i class="ni ni-lock-circle-open"></i>
                            <span>Reset password</span>
                        </div>
                        <div class="dropdown-divider"></div>
                        <router-link to="/logout" class="dropdown-item">
                            <i class="ni ni-user-run"></i>
                            <span>Logout</span>
                        </router-link>
                    </base-dropdown>
                </ul>
            </slot>
            <slot></slot>
            <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">

                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <img :src="logo">
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                        </div>
                    </div>
                </div>

                <ul class="navbar-nav">
                    <slot name="links">
                    </slot>
                </ul>
            </div>
            </div>
    </nav>
</template>
<script>
  import NavbarToggleButton from '@/components/NavbarToggleButton'
    import { mapState } from "vuex"

  export default {
    name: 'sidebar',
    components: {
      NavbarToggleButton
    },
    props: {
      logo: {
        type: String,
        default: '/img/brand/vidsprint.png',
        description: 'Sidebar app logo'
      },
      autoClose: {
        type: Boolean,
        default: true,
        description: 'Whether sidebar should autoclose on mobile when clicking an item'
      }
    },
    provide() {
      return {
        autoClose: this.autoClose
      };
    },
    computed: {
        ...mapState('AUTH', {
            user: state => state.user,
        }),
        ...mapState('USER', {
            userData: state => state.user,
        }),
        user_photo() {
            return this.userData?.profile_image || this.user?.profile_image
        },
        photo() {
            return this.user?.photo?.content_link
        },
        username() {
            if (!this.user)
                return ''

            const fname = this.$lodash.upperFirst(this.user?.first_name)
            const lname = this.$lodash.upperFirst(this.user?.last_name)

            return `${fname} ${lname}`
        }
    },
    methods: {
      closeSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      showSidebar() {
        this.$sidebar.displaySidebar(true)
      },
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar() {
            this.$sidebar.displaySidebar(false);
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        handleRSettings(){
            this.$router.push(`/settings`)
        },
        handleNavigateProfile() {
            this.$router.push(`/user/${this?.user?.id}`)
        },
        async handleResetPasword() {
            const request = await this.$store
                .dispatch('AUTH/requestForgotPassword', {
                    email: this?.user?.email
                })

            if (request) {
              this.$notify({
                timeout: 10000,
                icon: 'ni ni-check-bold',
                type: 'success',
                title: 'Password Reset Sent',
                message: `Sucessfuly sent`
              })

              this.$router.push({name: 'login'})
            } else {
              this.$notify({
                icon: 'fa fa-times',
                type: 'danger',
                title: 'Error',
                message: `Something went wrong!`
              })
            }
        }
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false;
      }
    }
  };
</script>
