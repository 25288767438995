import axios from '@/utils/axios'

const DEFAULT_STATE = () => ({
	data: [],
	loading: false,
})

const ACTIONS = {
	
	async listKnowledgeBase(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/base/knowledge_base?${payload}`,
				method: 'GET',
			})

			ctx.commit('setListData', response)
			return response
		} catch(err) {
			console.log('listKnowledgeBaseErr', err)
		} finally {
			ctx.commit('setLoading', false)
		}
	},

	async createKnowledgeBase(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			return await axios.request({
				url: 'base/knowledge_base',
				method: 'POST',
				data: payload,
			})
		} catch(err) {
			console.log('createKnowledgeBaseErr', err)
		} finally {
			ctx.commit('setLoading', false)
		}
	},

	async updateKnowledgeBase(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			return await axios.request({
				url: `base/knowledge_base/${payload.id}`,
				method: 'PUT',
				data: payload,
			})
		} catch(err) {
			console.log('updateKnowledgeBaseErr', err)
		} finally {
			ctx.commit('setLoading', false)
		}
	},

	async deleteKnowledgeBase(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			return await axios.request({
				url: `base/knowledge_base/${payload.id}`,
				method: 'PUT',
				data: payload,
			})
		} catch(err) {
			console.log('deleteKnowledgeBaseErr', err)
		} finally {
			ctx.commit('setLoading', false)
		}
	},

}

const MUTATIONS = {
	setListData(state, payload) {
		state.data = payload
	},
	setLoading(state, payload) {
		state.loading = payload
	},
	clear(state) {
		Object.assign(state, DEFAULT_STATE() )
	}
}

const GETTERS = {}

const KNOWLEDGE_BASE = {
	namespaced: true,
	state: DEFAULT_STATE() ,	
	actions:ACTIONS,
	mutations: MUTATIONS,
	getters: GETTERS
}

export default KNOWLEDGE_BASE