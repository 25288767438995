import axios from '@/utils/axios'
import pick from 'lodash/pick'
import lowerCase from 'lodash/lowerCase'
import router from '@/router'
import Vue from 'vue';

const DEFAULT_STATE = () => ({
	user: null,
	csrf: '',
	sessionRequested: false,
	loginLoading: false,
	isTokenValid: null,
	errorMessage: '',
	app_settings: null
})

const ACTIONS = {
	async authenticate(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: '/auth/login',
				method: 'POST',
				data: payload,
			})

			const user = pick(
				response,
				'email',
				'user_name',
				'role',
				'first_name',
				'last_name',
				'id',
				'verified',
				'client',
				'profile_image',
				'filter_settings'
			)

			const setting = pick(response, 'app_settings')

			ctx.commit('authorize', user)
			ctx.commit('setAppSettings', setting)
			ctx.commit('setLoading', false)

			return user
		} catch(err) {
			// keep quite
		}
	},

	async requestUserSession(ctx) {
		try {
			if (ctx.state.csrf && ctx.state.user)
				return

			const { csrf = '' } = await axios.request({
				url: '/auth/csrf',
			})

			ctx.commit('setCsrf', csrf)
			const response = await axios.request({
				url: '/auth/session',
			})

			const user = pick(
				response,
				'email',
				'user_name',
				'role',
				'first_name',
				'last_name',
				'id',
				'verified',
				'client',
				'profile_image',
				'filter_settings'
			)

			const setting = pick(response, 'app_settings')

			ctx.commit('setAppSettings', setting)
			ctx.commit('authorize', user)
			return user
		} catch(err) {
			// keep quite
		}
	},

	async requestForgotPassword(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: '/auth/forgot-password',
				method: 'POST',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},

	async requestResetPassword(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: '/auth/reset-password',
				method: 'PUT',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},

	async requestValidateToken(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `/auth/validate-token?${payload}`,
			})

			ctx.commit('setLoading', false)
			ctx.commit('setTokenValid', {
				valid: true,
				message: null
			})
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			ctx.commit('setTokenValid', {
				valid: false,
				message: err?.response?.data?.message || 'Token is invalid'
			})
		}
	},

	async revokeAccess(ctx) {
		try {
			var payload = {
				'size':localStorage.getItem('size'),
				'value':localStorage.getItem('value'),
				'sortColumn':localStorage.getItem('sortColumn'),
				'sortDirection':localStorage.getItem('sortDirection'),
			}
			console.log("payload", payload);
			await axios.request({
				url: '/auth/logout',
				method: 'POST',
				data:payload
			})
			localStorage.removeItem('size');
			localStorage.removeItem('value');
			localStorage.removeItem('sortColumn');
			localStorage.removeItem('sortDirection');
			localStorage.clear()
			ctx.commit('unauthorize')
		} catch(err) {
			// keep quite
		}
	},

	revokeSession(ctx) {
		try {
			if (ctx?.state?.user) {
				Vue.prototype.$notify({
					icon: 'fa fa-exclamation-triangle',
					type: 'danger',
					title: 'Unauthorized',
					message: 'Authorization is required'
				})

				router.push({name: 'login'})
			}

			ctx.commit('unauthorize')
		} catch(err) {
			// keep quite
		}
	},

	async updateAppSettings(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `/user/settings`,
				method: 'PUT',
				data: payload
			})
			
			const setting = pick(response, 'app_settings')

			ctx.commit('setAppSettings', setting)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			return Promise.reject(err)
		}
	}

	/*
		async requestShipmentLoad(ctx) {
			if (ctx.getters.shipmentsCount)
				return

			ctx.commit('setLoading', true)

			const res = await
				fetch('http://localhost:3010/shipments')
				.then(response => response.json())

			ctx.commit('clearShipment')
			ctx.commit('setShipments', res)
		},

		async requestShipmentData(ctx, { shipment_id }) {
			ctx.commit('setLoading', true)

			const res = await
				fetch(`http://localhost:3010/shipments/${shipment_id}`)
				.then(response => response.json())

			ctx.commit('setLoading', false)
			ctx.commit('setModified', false)
			ctx.commit('setShipmentData', res)
		},

		async requestShipmentUpdate(ctx) {
			ctx.commit('setLoading', true)
			const { data } = ctx.state

			await fetch(`http://localhost:3010/shipments/${data.id}`, {
				method: 'PUT',
				headers: {
				'Content-type': 'application/json; charset=UTF-8'
				},
				body: JSON.stringify(data)
			})
			.then(response => response.json())

			ctx.commit('setModified', false)
		},

		editCargoBox(ctx, payload) {
			if (ctx.state.data.id !== payload.id)
				return

			ctx.commit('setModified', true)
			ctx.commit('setShipmentData', {
				...ctx.state.data,
				...payload
			})
		}
	*/


	
}

const MUTATIONS = {
	authorize(state, payload) {
		state.user = payload
	},
	unauthorize(state) {
		state.user = null
	},
	authRequest(state) {
		state.sessionRequested = true
	},
	setLoading(state, payload) {
		state.loginLoading = payload
	},
	setTokenValid(state, payload) {
		state.errorMessage = payload.message
		state.isTokenValid = payload.valid
	},
	setCsrf(state, payload) {
		state.csrf = payload
	},
	clearAuth(state) {
		Object.assign(state, DEFAULT_STATE() )
	},
	setAppSettings(state, payload) {
		state.app_settings = payload
	}
}

const GETTERS = {
	isAdmin: state => {
		return state.user?.role === 'Admin'
	},
	isUser: state => {
		return state.user?.role === 'User'
	},
	isEditor: state => {
		return state.user?.role === 'Editor'
	},
	isCreativeDirector: state => {
		return state.user?.role === 'Creative Director'
	},
	userRole: state => {
		return state.user?.role || 'User'
	},
	userId: state => {
		return state.user?.id || ''
	},
	client: state => {
		return state.user?.client
	},
	isClientActive: state => {
		return lowerCase(state.user?.client?.status || 'active') === 'active'
	},
	isUserAndClientIsNotActive: (state, getters) => {
		return getters.isUser && !getters.isClientActive
	},
	csrf: state => {
		return state?.csrf || ''
	},
	searchValue: state =>{
		return state.user?.filter_settings?.search?.value || ''
	},
	filterSize: state =>{
		return state.user?.filter_settings?.size || 10
	},
	filterSort: state =>{
		return state.user?.filter_settings?.sort
	},
	filterSettings: state =>{
		return state.user?.filter_settings
	},

	/*
		shipments: state => state.shipments,
		shipmentsCount: state => state.shipments.length,
		modified: state => state.modified,
		loading: state => state.loading,
		updated: state => state.updated,
		data: state => state.data,
		filteredShipment(state, getters) {
			if (!getters.shipmentsCount)
				return []

			if (state.keyword)
				return state.shipments
					.filter(({ name }) => name.toLowerCase().includes(state.keyword.toLowerCase()))

			return state.shipments
		}
	*/
}

const AUTH = {
	namespaced: true,
	state: DEFAULT_STATE() ,
	actions:ACTIONS,
	mutations: MUTATIONS,
	getters: GETTERS
}

export default AUTH