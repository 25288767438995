<template>
	<table class="table tablesorter" :class="tableClass">
		<thead :class="theadClasses">
			<tr>
				<slot name="columns" :columns="columns">
					<th v-for="(column, index) in columns" :key="index" :width="column.width" :class="column.class">
						<div
							class="table-header-sort"
							:class="{'disable': !column.sort}"
							@click="handleSort(column, sortDirection)"
						>
							{{ typeof column === 'string' ? column : column.name }}
							<i
								class="ni table-header-sort"
								v-if="column.sort && sortColumn === column.key"
								:class="{
									'ni-bold-down': sortDirection === 'desc',
									'ni-bold-up': sortDirection !== 'desc'
								}"
							/>
						</div>
					</th>
				</slot>
			</tr>
		</thead>
		<tbody :class="tbodyClasses">
			<template v-for="(item, index) in data">
				<slot :row="item" :index="index">
					<tr :key="index">
						<td
							v-for="(column, index) in colsWithValue(item)"
							:key="index">
							{{ itemValue(item, column) }}
						</td>
					</tr>
				</slot>
			</template>
		</tbody>
	</table>
</template>
<script>
export default {
	name: 'base-table',
	props: {
		sortDirection: {
			type: String,
			default: 'desc',
			description: 'sort direction'
		},
		sortColumn: {
			type: String,
			default: 'created_date',
			description: 'column to sort'
		},
		columns: {
			type: Array,
			default: () => [],
			description: 'Table columns'
		},
		data: {
			type: Array,
			default: () => [],
			description: 'Table data'
		},
		type: {
			type: String, // striped | hover
			default: '',
			description: 'Whether table is striped or hover type'
		},
		theadClasses: {
			type: String,
			default: '',
			description: '<thead> css classes'
		},
		tbodyClasses: {
			type: String,
			default: '',
			description: '<tbody> css classes'
		}
	},
	computed: {
		tableClass() {
			return this.type && `table-${this.type}`;
		},
		colsWithValue() {
			return (row) => {
				return this.columns.filter(column => this.hasValue(row, column))
			}
		}
	},
	methods: {
		hasValue(item, column) {
			if (typeof column === 'string')
				return item[column.toLowerCase()] !== 'undefined'

			return item[column.name.toLowerCase()] !== 'undefined'
		},
		itemValue(item, column) {
			if (typeof column === 'string')
				return item[column.toLowerCase()]

			return item[column.name.toLowerCase()]
		},
		handleSort(column, sortDirection) {
			if (!column.sort)
				return

			this.$emit('input', {
				column: column.key,
				direction: sortDirection === "desc" ? 'asc' : 'desc'
			})
		}
	}
};
</script>
<style></style>
