<template>
    <pulse-loader :loading="loading" :color="color" :size="size" class="loader"/>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: "loader",
  props: {
    loading: {
      type: Boolean,
      default: false,
      description: ""
    },
    color: {
      type: String,
      default: "#1cb845",
      description: ""
    },
    size: {
      type: String,
      default: "20px",
      description: ""
    },
   
  },
  components: {
    PulseLoader
  }
};
</script>
<style scoped>
  .loader {
    text-align: center;
  }
</style>>

