import axios from '@/utils/axios'
// import pick from 'lodash/pick'

const DEFAULT_STATE = () => ({
	data: [],
	total: 0,
	user: null,
	loading: false,
	error: null,
})

const ACTIONS = {
	async createUser(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: '/user',
				method: 'POST',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(error) {
			ctx.commit('setError', error)
		}
	},

	async getUserList(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/base/user_details?${payload}`,
				method: 'GET',
			})

			ctx.commit('setListData', response)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},

	async getUserData(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/base/user_details/${payload}`,
				method: 'GET',
			})

			ctx.commit('setUser', response)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			return Promise.reject(err)
		}
	},

	async updateUser(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `user/profile/${payload.id}`,
				method: 'PUT',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},

	async deleteUser(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `base/user/${payload.id}`,
				method: 'DELETE',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},
	async getUserDataByEmailNoCommit(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/base/user_details/email/?email=${payload}`,
				method: 'GET',
			})
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			return Promise.reject(err)
		}
	},

}

const MUTATIONS = {
	setListData(state, payload) {
		state.data = payload.data
		state.total = payload.count
	},
	setUser(state, payload) {
		state.user = payload
	},
	setLoading(state, payload) {
		state.loading = payload
	},
	setError(state, payload) {
		state.error = payload
	},
	clearAuth(state) {
		Object.assign(state, DEFAULT_STATE() )
	}
}

const GETTERS = {
	/*
		shipments: state => state.shipments,
		shipmentsCount: state => state.shipments.length,
		modified: state => state.modified,
		loading: state => state.loading,
		updated: state => state.updated,
		data: state => state.data,
		filteredShipment(state, getters) {
			if (!getters.shipmentsCount)
				return []

			if (state.keyword)
				return state.shipments
					.filter(({ name }) => name.toLowerCase().includes(state.keyword.toLowerCase()))

			return state.shipments
		}
	*/
}

const USER = {
	namespaced: true,
	state: DEFAULT_STATE() ,
	actions:ACTIONS,
	mutations: MUTATIONS,
	getters: GETTERS
}

export default USER