import Vue from 'vue'
import Router from 'vue-router'
import upperFirst from 'lodash/upperFirst'

import store from '@/store'
import AdminLayout from '@/layout/AdminLayout'
// import DashboardLayout from '@/layout/DashboardLayout'

// import AuthLayout from '@/layout/AuthLayout'

Vue.use(Router)

const routes = new Router({
	linkExactActiveClass: 'active',
	mode: 'history',
	routes: [
		{
			path: '/',
			redirect: 'request',
			component: AdminLayout,
			children: [
				{
					path: '/client',
					name: 'client',
					component: () => import('@/container/client'),
					meta: {
						requireAuth: true,
						requiredRoles: ['Admin', 'Editor', 'Creative Director']
					}
				},
				{
					path: '/client/:id',
					name: 'client-profile',
					component: () => import('@/container/client/ClientProfileContainer'),
					meta: {
						requireAuth: true,
						requiredRoles: ['Admin', 'Editor', 'Creative Director']
					}
				},
				{
					path: '/user-invite',
					name: 'user invite',
					component: () => import('@/container/user-invite'),
					meta: {
						requireAuth: true,
						requiredRoles: ['Admin']
					},
				},
				{
					path: '/user',
					name: 'user',
					component: () => import('@/container/user'),
					meta: {
						requireAuth: true,
						requiredRoles: ['Admin']
					},
				},
				{
					path: '/user/:id',
					name: 'user-profile',
					component: () => import('@/container/user/UserProfileContainer'),
					meta: {
						requireAuth: true,
						requiredRoles: ['Admin', 'User', 'Editor', 'Creative Director']
					},
				},
				{
					path: '/settings',
					name: 'settings',
					component: () => import('@/container/settings/SettingsContainer'),
					meta: {
						requireAuth: true,
						requiredRoles: ['Admin', 'User', 'Editor', 'Creative Director']
					},
				},
				{
					path: '/request',
					name: 'request',
					component: () => import('@/container/request'),
					meta: {
						requireAuth: true,
						requiredRoles: ['Admin', 'User', 'Editor', 'Creative Director']
					},
				},
				{
					path: '/request/:id',
					name: 'request details',
					component: () => import('@/container/request-details'),
					meta: {
						requireAuth: true,
						requiredRoles: ['Admin', 'User', 'Editor', 'Creative Director'],
						previousRoute: {link: 'request', label: 'Back to projects'}
					},
				},
				{
					path: '/question',
					name: 'Request Questions',
					component: () => import('@/container/question'),
					meta: {
						requireAuth: true,
						requiredRoles: ['Admin']
					},
				},
				{
					path: '/custom-question',
					name: 'Custom Questions',
					component: () => import('@/container/templates'),
					meta: {
						requireAuth: true,
						requiredRoles: ['Admin']
					},
				},
				{
					path: '/templates/:id',
					name: 'template details',
					component: () => import('@/container/template-details'),
					meta: {
						requireAuth: true,
						requiredRoles: ['Admin', 'User', 'Editor', 'Creative Director']
					},
				},
				{
					path: '/knowledge-base',
					name: 'knowledge base',
					component: () => import('@/container/knowledge-base/KnowledgeBaseContainer'),
					meta: {
						requireAuth: true,
						requiredRoles: ['Admin', 'User', 'Editor', 'Creative Director']
					},
				}

			],
			meta: {
				requireAuth: true,
				requiredRoles: ['Admin', 'User', 'Editor', 'Creative Director']
			}
		},
		// {
		// 	path: '/',
		// 	redirect: 'dashboard',
		// 	component: DashboardLayout,
		// 	children: [
		// 		{
		// 			path: '/dashboard',
		// 			name: 'dashboard',
		// 			component: () => import('./views/Dashboard.vue'),
		// 			meta: {
		// 				requireAuth: true,
		// 				requiredRoles: ['Admin']
		// 			}
		// 		},
		// 		{
		// 			path: '/icons',
		// 			name: 'icons',
		// 			component: () => import('./views/Icons.vue'),
		// 			meta: {
		// 				requireAuth: true,
		// 				requiredRoles: ['Admin', 'User']
		// 			}
		// 		},
		// 		{
		// 			path: '/profile',
		// 			name: 'profile',
		// 			component: () => import('./views/UserProfile.vue'),
		// 			meta: {
		// 				requireAuth: true,
		// 				requiredRoles: ['Admin']
		// 			}
		// 		},
		// 		{
		// 			path: '/maps',
		// 			name: 'maps',
		// 			component: () => import('./views/Maps.vue'),
		// 			meta: {
		// 				requireAuth: true,
		// 				requiredRoles: ['Admin']
		// 			}
		// 		},
		// 		{
		// 			path: '/tables',
		// 			name: 'tables',
		// 			component: () => import('./views/Tables.vue'),
		// 			meta: {
		// 				requireAuth: true,
		// 				requiredRoles: ['Editor', 'Creative Director', 'Admin']
		// 			}
		// 		}
		// 	],
		// 	meta: {
		// 		requireAuth: true,
		// 		requiredRoles: ['Admin', 'User', 'Editor', 'Creative Director']
		// 	}
		// },
		{
			path: '/login',
			name: 'login',
			component: () => import('./views/Login.vue'),
			meta: {
				requireAuth: false,
				requiredRoles: []
			}
		},
		{
			path: '/setup-account',
			name: 'setup-account',
			component: () => import('@/container/register'),
			meta: {
				requireAuth: false,
				requiredRoles: []
			}
		},
		{
			path: '/forgot-password',
			name: 'forgot-password',
			component: () => import('@/container/forgot-password'),
			meta: {
				requireAuth: false,
				requiredRoles: []
			}
		},
		{
			path: '/reset-password',
			name: 'reset-password',
			component: () => import('@/container/reset-password'),
			meta: {
				requireAuth: false,
				requiredRoles: []
			}
		},
		{
			path: '/forbidden',
			name: 'forbidden',
			component: () => import('./views/Forbidden.vue'),
			meta: {
				requireAuth: true,
				requiredRoles: ['Admin', 'User', 'Editor', 'Creative Director']
			}
		},
		{
			path: '/logout',
			name: 'logout',
			meta: {
				requireAuth: true,
				requiredRoles: ['Admin', 'User', 'Editor', 'Creative Director']
			}
		},
		// {
		// 	path: '/test',
		// 	name: 'test',
		// 	component: () => import('./views/Forbidden.vue'),
		// 	meta: {
		// 		requireAuth: false,
		// 		requiredRoles: ['Admin', 'User', 'Editor', 'Creative Director']
		// 	}
		// },
	]
})

function hasAccess(roles, role) {
	return roles?.length && roles.includes(role)
}

function isSameRoute(routeName, name) {
	return routeName?.includes(name)
}

/* NOTE: do not destruct store object */
routes.beforeEach(async (to, from, next) => {
	const { matched } = to
	const expect = ['reset-password']

	if (!store.state.AUTH.sessionRequested) {
		await store.dispatch('AUTH/requestUserSession')
	}

    if(store.state.AUTH.sessionRequested) {
		if(to.name === 'request details') {
			const request_id = to.params.id;
			await store.dispatch('REQUEST/getRequestData', request_id);
			console.log('request', store.state.REQUEST.request)
		}
	}
 
	const [route, route2] = matched
	if (route || route2){
		if(route?.name  == 'request'  ||  route2?.name =='request'){
			document.title = 'VidSprint'
		}else{
			const r1names = (route?.name?.split('-'))?.map(name => upperFirst(name)).join(' ')
			const r2names = (route2?.name?.split('-'))?.map(name => upperFirst(name)).join(' ')
			
			document.title = ( r1names||r2names|| '') + ' | VidSprint';
		}
		
	}
	if (matched.some(({ name }) => expect.includes(name))) {
		return next()
	}

	if (!store.state.AUTH.user && matched.some(({ name, meta }) => name !== 'login' && !meta?.requireAuth)) {
		return next()
	}

	if (!store.state.AUTH.user && !matched.some(({ name }) => isSameRoute(name, 'login'))) {
		return next({ name: 'login' })
	}

	if (
		store.state.AUTH.user &&
		matched.some(({ meta, name }) =>  (
			meta?.requireAuth &&
			name?.includes('logout') &&
			!isSameRoute(name, from.name || 'logout') &&
			hasAccess(meta?.requiredRoles, store.state.AUTH.user?.role)
		))
	) {
		await store.dispatch('AUTH/revokeAccess')
		return next({ name:'login' })
	}

	if (
		store.state.AUTH.user &&
		matched.some(({ meta, name }) => (
			meta?.requireAuth &&
			!isSameRoute(name, from.name || 'request') &&
			!hasAccess(meta?.requiredRoles, store.state.AUTH.user?.role)
		))
	) {
		return next({ name: 'request' })
	}

	if (
		store.state.AUTH.user &&
		matched.some(({ meta, name }) => (!meta?.requireAuth && !isSameRoute(name, from.name || 'request')))
	) {
		return next({ name: from.name || 'request' })
	}

	return next()
})


export default routes;