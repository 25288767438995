import axios from '@/utils/axios'
// import pick from 'lodash/pick'

const DEFAULT_STATE = () => ({
	data: [],
	total: 0,
	invite: null,
	loading: false,
})

const ACTIONS = {
	async createInvite(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: '/invite/send',
				method: 'POST',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},

	async approveInvite(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: '/invite/approve',
				method: 'POST',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},

	async resendInvite(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `/invite/resend/${payload.id}`,
				method: 'POST',
				data: payload,
			})
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},

	async getInviteList(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/base/invitation_details?${payload}`,
				method: 'GET',
			})

			ctx.commit('setListData', response)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},

	async getInviteData(ctx, payload) {
		try {
			const response = await axios.request({
				url: `/base/invitation_details/${payload}`,
				method: 'GET',
			})

			ctx.commit('setInvite', response)
			return response
		} catch(err) {
			return Promise.reject(err)
		}
	},

	async updateInvite(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `base/invitation/${payload.id}`,
				method: 'PUT',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},

	async deleteInvite(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `base/invitation/${payload.id}`,
				method: 'DELETE',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},

}

const MUTATIONS = {
	setListData(state, payload) {
		state.data = payload.data
		state.total = payload.count
	},
	setInvite(state, payload) {
		state.invite = payload
	},
	setLoading(state, payload) {
		state.loading = payload
	},
	clearInvite(state) {
		Object.assign(state, DEFAULT_STATE() )
	}
}

const GETTERS = {
	/*
		shipments: state => state.shipments,
		shipmentsCount: state => state.shipments.length,
		modified: state => state.modified,
		loading: state => state.loading,
		updated: state => state.updated,
		data: state => state.data,
		filteredShipment(state, getters) {
			if (!getters.shipmentsCount)
				return []

			if (state.keyword)
				return state.shipments
					.filter(({ name }) => name.toLowerCase().includes(state.keyword.toLowerCase()))

			return state.shipments
		}
	*/
}

const INVITE = {
  namespaced: true,
  state: DEFAULT_STATE() ,
  actions:ACTIONS,
  mutations: MUTATIONS,
  getters: GETTERS
}

export default INVITE