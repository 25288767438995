import "@/assets/scss/fonts/inter.css";
import "@/assets/vendor/nucleo/css/nucleo.css";
import "@/assets/scss/argon.scss";
import 'vue-multiselect/dist/vue-multiselect.min.css';

import globalComponents from "./globalComponents";
import globalDirectives from "./globalDirectives";
import SidebarPlugin from "@/components/SidebarPlugin/index"
import NotificationPlugin from "@/components/NotificationPlugin/index"
import VueNativeSock from 'vue-native-websocket'
import VueIframe from 'vue-iframes'
import VueCryptojs from 'vue-cryptojs'

import moment from 'moment'
import lodash from 'lodash'
import tools from '@/utils/tools'

export default {
  install(Vue) {
    Vue.use(globalComponents);
    Vue.use(globalDirectives);
    Vue.use(SidebarPlugin);
    Vue.use(NotificationPlugin);
    Vue.use(VueNativeSock, 'ws://localhost:5000/websockets', {
      connectManually: true,
      reconnection: true,  
      reconnectionAttempts: 5,
      reconnectionDelay: 3000
    });
    Vue.use(VueIframe);
    Vue.use(VueCryptojs);
    
    Object.defineProperty(Vue.prototype, '$moment', { value: moment })
    Object.defineProperty(Vue.prototype, '$lodash', { value: lodash })
    Object.defineProperty(Vue.prototype, '$tools', { value: tools })
  }
};
