
export default {
	capitalize(string = '') {
		return string.charAt(0).toUpperCase() + string.slice(1)
	},

	randomNumber(max = 100) {
		return Math.floor(Math.random() * max) + 1
	},

    getInitial(string = '') {
        [string] = string.split('@')
        const initials = string.match(/\b\w/g) || []
        return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    },

    validateEmail(email = '') {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
}