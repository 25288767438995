import axios from 'axios'
import store from '@/store'
// import router from '@/router'
import Vue from 'vue';
// import store from 'shared/redux/store'
// import { unauthorize } from 'shared/redux/auth/reducer'
// import { showError, Notification } from 'shared/redux/app/reducer'

// const baseURL = '/api'


const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL,
})

const noErrorToastMessageRoutes = [/*'/auth/login'*/]

axiosInstance.interceptors.request.use(
	function(config) {
		const buffer = Buffer.from(
			`${process.env.VUE_APP_API_USERNAME}:${process.env.VUE_APP_API_PASSWORD}`,
		)

		config.headers['x-csrf-token'] = store.state.AUTH.csrf
		config.headers['Authorization'] = `Basic ${buffer.toString('base64')}`
		return config
	},
	(err) => {
		return Promise.reject(err)
	},
)

axiosInstance.interceptors.response.use(
	function(response) {
		return response.data
	},
	function(error) {
		const { response } = error
		if (response?.status === 401) {
			store.dispatch('AUTH/revokeSession')
		} else if (response?.status === 500) {
			Vue.prototype.$notify({
				icon: 'fa fa-exclamation-triangle',
				type: 'danger',
				title: 'Server Error',
				message: 'Something went wrong'
			})
		} else if (response?.status === 400 && !noErrorToastMessageRoutes.some(e => response.config.url?.includes(e))) {
			Vue.prototype.$notify({
				icon: 'fa fa-exclamation-triangle',
				type: 'danger',
				title: 'Error',
				message: response.data.message?.replace(/Request/g, 'Project') || 'Something went wrong'
			})
		}
		// Do something with response error
		return Promise.reject(error)
	},
)

export default axiosInstance
