import axios from '@/utils/axios'
import uniqBy from 'lodash/uniqBy'
import Vue from 'vue';

const DEFAULT_STATE = () => ({
	data: [],
	total: 0,
	questions: [],
	custom_questions: [],
	comments: [],
	selectedComment: null,
	logs:[],
	request: null,
	loading: false,
	collaborators: [],
	commentSortDirection: 'desc',
	logsSortDirection:'desc',
	progressLoading: false,
	deliverableList: [],
	deliverableListTotal: 0,
	deliverableOrders: {}
})

const ACTIONS = {
	async createRequest(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: '/request',
				method: 'POST',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			// keep quite
		}
	},

	async createRequestV3(ctx, payload) {
		try {
			ctx.commit('setProgressLoading', true)
			
			const response = await axios.request({
				url: '/request/v3',
				method: 'POST',
				data: payload,
			})
			
			ctx.dispatch('getRequestData', response.id)

			return response
		} catch(err) {
			console.log('createRequestErr', err)
		} finally {
			ctx.commit('setProgressLoading', false)
		}
	},

	async createDeliverable(ctx, payload) {
		try {
			ctx.commit('setProgressLoading', true)
			
			const response = await axios.request({
				url: '/deliverables',
				method: 'POST',
				data: payload,
			})

			return response
		} catch(err) {
			console.log('createDeliverableErr', err)
		} finally {
			ctx.commit('setProgressLoading', false)
		}
	},

	async updateDeliverable(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			
			const response = await axios.request({
				url: `/base/deliverable/${payload.id}`,
				method: 'PUT',
				data: payload,
			})

			return response
		} catch(err) {
			console.log('updateDeliverableErr', err)
		} finally {
			ctx.commit('setLoading', false)
		}
	},

	async updateDeliverableStatus(ctx, payload) {
		try {
			const response = await axios.request({
				url: `/deliverables/${payload.id}/status`,
				method: 'PUT',
				data: payload,
			})

			return response
		} catch(err) {
			console.log('updateDeliverableStatusErr', err)
		}
	},

	async deleteDeliverable(ctx, payload) {
		try {
			const response = await axios.request({
				url: `/base/deliverable/${payload}`,
				method: 'DELETE',
			})

			return response
		} catch(err) {
			return Promise.reject(err)
		} finally {
			ctx.commit('setProgressLoading', false)
		}
	},

	async deleteBulkDeliverables(ctx, payload) {
		try {
			ctx.commit('setProgressLoading', true)
			const response = await axios.request({
				url: `/base/deliverable/${payload.id}`,
				data: payload,
				method: 'DELETE',
			})
			
			return response
		} catch(err) {
			return Promise.reject(err)
		} finally {
			ctx.commit('setProgressLoading', false)
		}
	},

	async listDeliverables(ctx, payload) {
		try {
			const response = await axios.request({
				url: `/deliverables?${payload}`,
				method: 'GET',
			})

			ctx.commit('setRequestDeliverables', response)

			return response
		} catch(err) {
			console.log('listDeliverableErr', err)
		}
	},

	async createRequestComment(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `/request/${payload?.request_id}/comment`,
				method: 'POST',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			// keep quite
		}
	},


	async updateNotify(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `/request/${payload.id}/notify`,
				method: 'PUT',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			// keep quite
		}
	},

	async updateCollaborator(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `/request/${payload.id}/collaborator`,
				method: 'PUT',
				data: payload,
			})

			ctx.commit('setLoading', false)
			ctx.dispatch('getRequestData', payload.id)
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			// keep quite
		}
	},

	async setCollaborators(ctx, payload) {
		try {
			const response = await axios.request({
				url: `/request/${payload.id}/collaborators`,
				method: 'PUT',
				data: payload,
			})

			ctx.dispatch('getRequestData', payload.id)
			return response
		} catch(err) {
			console.log('setCollaboratorstErr', err)
		}
	},

	async updateRequest(ctx, payload) {
		try {
			console.log("Update Request",payload)
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `/request/${payload.id}`,
				method: 'PUT',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			// keep quite
		}
	},

	async updateRequestV3(ctx, payload) {
		try {
			ctx.commit('setProgressLoading', true)
			
			const response = await axios.request({
				url: `/request/${payload.id}/v3`,
				method: 'PUT',
				data: payload,
			})

			ctx.dispatch('getRequestData', response.id)

			return response
		} catch(err) {
			console.log('updateRequestErr', err)
		} finally {
			ctx.commit('setProgressLoading', false)
		}
	},

	async updateRequestStatus(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `/request/${payload.id}/status`,
				method: 'PUT',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			throw err
		}
	},

	async updateRequestReviewLink(ctx, payload) {
		// eslint-disable-next-line no-useless-catch
		try {
			return await axios.request({
				url: `/request/${payload.id}/review-link`,
				method: 'PUT',
				data: payload,
			})
		} catch(err) {
			throw err
		}
	},

	async updateReceiveDate(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `/request/${payload.id}/receive`,
				method: 'PUT',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			// keep quite
		}
	},

	async getRequestList(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `/request?${payload}`,
				method: 'GET',
			})

			ctx.commit('setListData', response)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			// keep quite
		}
	},

	async getUserRequestList(ctx, { user_id, payload, is_user}) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `/user/${user_id}/request?${payload}`,
				method: 'GET',
			})

			if(is_user) {
				if(response) {
					response.data.map(r => {
						if(r.request_status === "Ready for review") {
							r.request_status = "In progress"
						}
					})
				}
			}

			ctx.commit('setListData', response)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			// keep quite
		}
	},

	async getAllRequestListData(ctx, payload) {
        try {
			ctx.commit('setLoading', true)

            const response = await axios.request({
                url: `/base/request_details?${payload}`,
                method: 'GET',
            })

            ctx.commit('setRequestListData', response)
			ctx.commit('setLoading', false)
            return response
        } catch(err) {
            return err
        }
    },

	async getRequestData(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/request/${payload}`,
				method: 'GET',
			})

			ctx.commit('setRequest', response)
			ctx.commit('setLoading', false)
			return response
		} catch(error) {
			const {message = 'Something went wrong' } = error?.response?.data
			Vue.prototype.$notify({
                icon: "fa fa-exclamation-triangle",
                type: "warning",
                title: "Forbidden",
                message: message,
            })

			throw error
		}
	},

	async getRequestQuestions(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/request/${payload}/question`,
				method: 'GET',
			})

			ctx.commit('setQuestion', response)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			return Promise.reject(err)
		}
	},
	async getClientCustomQuestions(ctx, payload) {
        try {
           // ctx.commit('setLoading', true)
            const response = await axios.request({
				url: `/request/${payload}/client_custom_question`,
				method: 'GET',
			})
           // ctx.commit('setLoading', false)
            ctx.commit('setCustomQuestion', response)
            return response
        } catch(err) {
            return err
        }
    },
	async getRequestCustomQuestions(ctx, payload) {
		try {
			//ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/request/${payload}/custom_question`,
				method: 'GET',
			})
			ctx.commit('setCustomQuestion', response)
			//ctx.commit('setLoading', false)
			return response
		} catch(err) {
			return Promise.reject(err)
		}
	},

	async getRequestComments(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/base/comment_details?${payload}`,
				method: 'GET',
			})
			let comments = [];
			response.forEach(e => {
				e.highlight = false;
				comments.push(e);
			});
			
			ctx.commit('setLoading', false)
			// ctx.commit('setComment', comments)
			return response
		} catch(err) {
			return Promise.reject(err)
		}
	},
	async getRequestLogs(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			console.log("getRequestLogs.......");
			const params = JSON.parse('{"' + decodeURI(payload.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
			
			if(params.affected_id == 'new'){
				
				const response = {count:0,data:[]}
				
				ctx.commit('setLoading', false)
				ctx.commit('setLog', response)
				return response
			}else{
				const  response = await axios.request({
					url: `/base/request_status_logs?${payload}`,
					method: 'GET',
				})
				ctx.commit('setLoading', false)
				ctx.commit('setLog', response)
				return response
			}
			
			
			
		} catch(err) {
			return Promise.reject(err)
		}
	},
	async getRequestCollaborators(ctx, payload) {
		try {
			const response = await axios.request({
				url: `/request/${payload.id}/collaborators?${payload.params}`,
				method: 'GET',
			})

			ctx.commit('setCollaborators', response)
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			return Promise.reject(err)
		}
	},
	async deleteRequest(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/base/request/${payload}`,
				method: 'DELETE',
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			return Promise.reject(err)
		}
	},
	async deleteComment(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/base/comment/${payload}`,
				method: 'DELETE',
			})
			console.log('response', response)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			return Promise.reject(err)
		}
	},

	async getRequestFeeds(ctx, payload) {
		try {
			const response = await axios.request({
				url: `/request/${payload.id}/feeds`,
				method: 'GET',
			})

			return response
		} catch(err) {
			return Promise.reject(err)
		}
	},

	async deleteBulkRequestFeeds(ctx, payload) {
		try {
			const response = await axios.request({
				url: `/base/feeds/${payload.id}`,
				data: payload,
				method: 'DELETE',
			})
			
			return response
		} catch(err) {
			return Promise.reject(err)
		}
	}
}

const MUTATIONS = {
	setListData(state, payload) {
		state.data = payload.data
		state.total = payload.count
		state.collaborators = payload.collaborators
	},

    setRequestListData(state, payload) {
        state.data = payload
    },
	setRequest(state, payload) {
		// if (state.request) {
		// 	payload = { ...payload, ...state.request }
		// }
		state.request = payload
	},
	setLoading(state, payload) {
		state.loading = payload
	},
	setProgressLoading(state, payload) {
		state.progressLoading = payload
	},
	setComment(state, payload) {
		state.comments = payload
	},
	setLog(state, payload) {
		state.logs = payload.data
		state.total = payload.count
	},
	setQuestion(state, payload) {
		state.questions = payload
	},
	setCustomQuestion(state, payload) {
		state.custom_questions = payload
	},
	setCollaborators(state, payload) {
		state.collaborators = payload
	},
	setRequestCollaborator(state, payload) {
		payload = uniqBy(payload, 'id')		
		state.request = {
			...state.request,
			collaborators: payload,
			members: payload.length ? payload.map(each => each?.id) : null
		}
	},
	setNotifyCollaborators(state, payload) {
		state.request = { ...state.request, properties: { ...state.request?.properties, notifyCollaborators: payload } }
	},
	setRequestDeliverableOrder(state, payload) {
		if (!payload) {
			state.deliverableOrders = {}
			return
		}
		
		const { id, type, deliverableOrders } = payload
		if (deliverableOrders) {
			state.deliverableOrders = deliverableOrders
			return 
		}

		state.deliverableOrders[type] = { ...state.deliverableOrders[type], [id]: payload }
		state.deliverableOrders = { ...state.deliverableOrders }
	},
	setRequestDeliverables(state, payload) {
		state.deliverableList = payload.data
		state.deliverableListTotal = payload.count
	},
	setCommentSortDirection(state, payload) {
		state.commentSortDirection = payload
	},
	setLogSortDirection(state, payload) {
		state.logsSortDirection = payload
	},
	setSelectedComment(state, payload) {
		state.selectedComment = payload
	},
	setStakeholder(state, payload) {
		state.request = { ...state.request, properties: { ...state.request?.properties, stakeholder: payload } }
	},
	resetState(state, payload) {
		const request = state.request || {}

		if (payload)
			request.client_id = payload.id
			request.client = payload
			request.collaborators = []
			request.members = null

		state.collaborators = []
		state.questions = [],
		state.custom_questions = []
		state.request = request
	},
	clearState(state) {
		Object.assign(state, DEFAULT_STATE() )
	},
}

const GETTERS = {
	requestId: state => {
		return state.request?.id || ''
	}
	/*
		shipments: state => state.shipments,
		shipmentsCount: state => state.shipments.length,
		modified: state => state.modified,
		loading: state => state.loading,
		updated: state => state.updated,
		data: state => state.data,
		filteredShipment(state, getters) {
			if (!getters.shipmentsCount)
				return []

			if (state.keyword)
				return state.shipments
					.filter(({ name }) => name.toLowerCase().includes(state.keyword.toLowerCase()))

			return state.shipments
		}
	*/
}

const REQUEST = {
	namespaced: true,
	state: DEFAULT_STATE() ,
	actions:ACTIONS,
	mutations: MUTATIONS,
	getters: GETTERS
}

export default REQUEST