<template>
  <div
    class="form-group"
    :class="[
       {'input-group': hasIcon},
       {'focused': focused},
       {'has-label': label || $slots.label},
       // {'has-danger': error},
       // {'has-success': value && error === '' && valid === true},
       // {'has-danger': error && valid === false}
       ]"
  >
    <slot name="label">
      <div v-if="label" class="d-flex justify-content-between align-items-center">
        <label class="form-control-label" :class="labelClasses">
          {{label}}
          <span v-if="required" class="text-red">*</span>
        </label>
        <slot name="label-icon">

        </slot>
      </div>
    </slot>
    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
      <span class="input-group-text">
        <slot name="addonLeft">
          <i :class="addonLeftIcon"></i>
        </slot>
      </span>
    </div>
    <slot v-bind="slotData">
      <input
        v-if="!textarea && !number"
        :value="value"
        v-on="listeners"
        v-bind="$attrs"
        class="form-control"
        :class="[
                     {'is-valid': value && valid === true},
                     {'is-invalid': error && valid === false}, inputClasses]"
        aria-describedby="addon-right addon-left"
      />

      <input 
        type="number"
        v-if="number"
        :value="value"
        v-on="listeners"
        v-bind="$attrs"
        class="form-control"
        :class="[
                     {'is-valid': value && valid === true},
                     {'is-invalid': error && valid === false}, inputClasses]"
        aria-describedby="addon-right addon-left"
        @keypress="validateNumber"
      />

      <textarea
        v-if="textarea"
        :value="value"
        v-on="listeners"
        v-bind="$attrs"
        class="form-control"
        :class="[
                     {'is-valid': value && valid === true},
                     {'is-invalid': error && valid === false}, inputClasses]"
        aria-describedby="addon-right addon-left"
        @mouseover="autosize"
      />
    </slot>
    <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
      <span class="input-group-text">
        <slot name="addonRight">
          <i :class="addonRightIcon"></i>
        </slot>
      </span>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div class="text-danger invalid-feedback" style="display: block;" :class="{'mt-2': hasIcon}" v-if="error">
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>
  export default {
    inheritAttrs: false,
    name: "base-input",
    props: {
      autoresize:{
        type: Boolean,
        description: "Whether textarea is auto resize or not",
        default: false,
      },
      required: {
        type: Boolean,
        description: "Whether input is required (adds an asterix *)",
      },
      valid: {
        type: Boolean,
        description: "Whether is valid",
        default: undefined,
      },
      label: {
        type: String,
        description: "Input label (text before input)",
      },
      error: {
        type: String,
        description: "Input error (below input)",
      },
      labelClasses: {
        type: String,
        description: "Input label css classes",
      },
      inputClasses: {
        type: String,
        description: "Input css classes",
      },
      value: {
        type: [String, Number],
        description: "Input value",
      },
      addonRightIcon: {
        type: String,
        description: "Addon right icon",
      },
      addonLeftIcon: {
        type: String,
        description: "Addont left icon",
      },
      textarea: {
        type: Boolean,
        default: false,
        description: "type either input or textarea",
      },
      number: {
        type: Boolean,
        default: false,
        description: "Input for number only"
      }
    },
    data() {
      return {
        focused: false,
      };
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          input: this.updateValue,
          focus: this.onFocus,
          blur: this.onBlur,
        };
      },
      slotData() {
        return {
          focused: this.focused,
          ...this.listeners,
        };
      },
      hasIcon() {
        const { addonRight, addonLeft } = this.$slots;
        return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
      },
    },
    methods: {
      autosize(event) {
        if (!this.autoresize)
          return

        const element = event.target
        setTimeout(function(){
          element.style.cssText = 'height:auto; padding:0';
          element.style.cssText = 'height:' + element.scrollHeight + 'px';
        },0)
      },
      updateValue(evt) {
        let value = evt.target.value;
        this.$emit("input", value);
      },
      onFocus(value) {
        this.focused = true;
        this.$emit("focus", value);
      },
      onBlur(value) {
        this.focused = false;
        this.$emit("blur", value);
      },
      validateNumber: event => {
        let keyCode = event.keyCode;
        if (keyCode < 48 || keyCode > 57) {
          event.preventDefault();
        }
    }
    },
  };
</script>
<style></style>
