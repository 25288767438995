import axios from '@/utils/axios'
// import pick from 'lodash/pick'

const DEFAULT_STATE = () => ({
	data: [],
	total: 0,
	template: null,
	loading: false,
})

const ACTIONS = {
	async createTemplate(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: 'base/templates',
				method: 'POST',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},
	async createTemplateQuestion(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: 'base/templates_question',
				method: 'POST',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},
	async addClientToTemplate(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			
			const response = await axios.request({
				url: `/template/${payload.id}/clients`,
				method: 'PUT',
				data: payload,
			});
			let d = {template_id: payload.id, client_id: payload.client_id}
			await axios.request({
				url: `base/template_client`,
				method: 'POST',
				data: d,
			});

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},
	
	async removeTemplateClients(ctx, payload) {
		
		try {
			ctx.commit('setLoading', true)
			
			const response = await axios.request({
				url: `/template/${payload.id}/removeclients`,
				method: 'PUT',
				data: payload,
			});

			let d = {template_id: payload.id, client_id: payload.client_id}
			const tc =await axios.request({
				url: `base/remove_template_client?template_id=${payload.id}&client_id=${payload.client_id}`,
				method: 'GET'
			});
			await axios.request({
				url: `base/template_client/${tc[0].id}`,
				method: 'DELETE',
				data: d,
			});
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},
	async getTemplateList(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/base/template_details?${payload}`,
				method: 'GET',
			})

			ctx.commit('setListData', response)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},
	async getTemplateQuestionsList(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/base/template_question_list?${payload}`,
				method: 'GET',
			})
			console.log(response.data);
			ctx.commit('setListQuestionData', response)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},
	async getAllTemplateClient(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/base/template_clients?template_id=${payload}`,
				method: 'GET',
			})
			console.log(response.data);
			ctx.commit('setTemplateClients', response)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},
	
	
	async getTemplateData(ctx, payload) {
		try {
			ctx.commit('setLoading', true)
			const response = await axios.request({
				url: `/base/template_details/${payload}`,
				method: 'GET',
			})

			ctx.commit('setTemplate', response)
			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			return Promise.reject(err)
		}
	},

	async updateTemplate(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `base/templates/${payload.id}`,
				method: 'PUT',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},
	async updateTemplateQuestion(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `base/templates_question/${payload.id}`,
				method: 'PUT',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},
	
	async deleteTemplate(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `base/question/${payload.id}`,
				method: 'DELETE',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},
	async deleteTemplateQuestion(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `base/templates_question/${payload.id}`,
				method: 'DELETE',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},
	async archiveTemplateClient(ctx, payload){
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `template/archive_template_client/${payload.id}`,
				method: 'POST',
				data: payload,
			})

			ctx.commit('setLoading', false)
			return response
		} catch(err) {
			// keep quite
		}
	},
	async updateTemplateClient(ctx, payload) {
		try {
			ctx.commit('setLoading', true)

			const response = await axios.request({
				url: `/template/${payload.id}/clients`,
				method: 'PUT',
				data: payload,
			})

			ctx.commit('setLoading', false)
			ctx.dispatch('getRequestData', payload.id)
			return response
		} catch(err) {
			ctx.commit('setLoading', false)
			// keep quite
		}
	},

}

const MUTATIONS = {
	setListData(state, payload) {
    state.data = payload.data
    state.total = payload.count
	},
	setTemplateClients(state, payload) {
	state.t_clients = payload
	},
	setListQuestionData(state, payload) {
	state.t_questions = payload.data
	state.total = payload.count
	},
	setTemplate(state, payload) {
    state.template = payload
	state.t_questions = payload.c_questions
	state.t_clients = payload.t_clients
	},
	setLoading(state, payload) {
    state.loading = payload
	},
	clearAuth(state) {
		Object.assign(state, DEFAULT_STATE() )
	}
}

const GETTERS = {
	/*
		shipments: state => state.shipments,
		shipmentsCount: state => state.shipments.length,
		modified: state => state.modified,
		loading: state => state.loading,
		updated: state => state.updated,
		data: state => state.data,
		filteredShipment(state, getters) {
			if (!getters.shipmentsCount)
				return []

			if (state.keyword)
				return state.shipments
					.filter(({ name }) => name.toLowerCase().includes(state.keyword.toLowerCase()))

			return state.shipments
		}
	*/
}

const TEMPLATES = {
	namespaced: true,
  state: DEFAULT_STATE() ,
  actions:ACTIONS,
  mutations: MUTATIONS,
	getters: GETTERS
}

export default TEMPLATES