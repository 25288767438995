<template>
  <nav
    class="navbar"
    :class="[
      {'navbar-expand-md': expand},
      {'navbar-transparent': transparent},
      {[`bg-${type}`]: type}
   ]"
  >
    <div :class="containerClasses">
      <slot name="brand">
        <a class="h4 mb-0 text-white d-none d-lg-inline-block cursor-pointer" href="#" @click.prevent="handleClick">
          <template v-if="hasBackNavigation">
            <img src="/img/icons/common/arrow-left.svg" class="image-icon mr-1 mt--1" />
            <span class="mb-0 text-sm font-weight-bold">{{ previousRouteLabel }}</span>
          </template>
          <template v-else>
            <div class="media align-items-center" slot="title" v-if="base64Image">
              <span class="avatar avatar-lg rounded-circle bg-success" >
                  <img alt="Image placeholder" :src="base64Image" />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                  <span class="mb-0 text-sm font-weight-bold text-capitalize">{{$route.name.replace(/-/g, ' ').replace(/request/g, title)}}</span>
              </div>
            </div>
            <span  v-if="!base64Image" class="mb-0 text-sm font-weight-bold text-capitalize">{{$route.name.replace(/-/g, ' ').replace(/request/g, title)}}</span>
          </template>
        </a>
      </slot>
      <navbar-toggle-button v-if="showToggleButton" :toggled="toggled" :target="contentId" @click.native.stop="toggled = !toggled">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>

      <div class="collapse navbar-collapse" :class="{show: toggled}" :id="contentId" v-click-outside="closeMenu">
        <slot :close-menu="closeMenu"></slot>
      </div>
    </div>
  </nav>
</template>
<script>
  import NavbarToggleButton from "./NavbarToggleButton";

  export default {
    name: "base-nav",
    components: {
      NavbarToggleButton,
    },
    props: {
      type: {
        type: String,
        default: "",
        description: "Navbar type (e.g default, primary etc)",
      },
      title: {
        type: String,
        default: "",
        description: "Title of navbar",
      },
      base64Image: {
        type: String,
        default: null,
        description: "Image link",
      },
      contentId: {
        type: [String, Number],
        default: Math.random().toString(),
        description: "Explicit id for the menu. By default it's a generated random number",
      },
      containerClasses: {
        type: [String, Object, Array],
        default: "container-fluid",
      },
      transparent: {
        type: Boolean,
        default: false,
        description: "Whether navbar is transparent",
      },
      expand: {
        type: Boolean,
        default: false,
        description: "Whether navbar should contain `navbar-expand-lg` class",
      },
      showToggleButton: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        toggled: false,
        hasBackNavigation: false
      };
    },
    created() {
      this.hasBackNavigation = this.$route.meta.previousRoute ? true : false
    },
    updated() {
      this.hasBackNavigation = this.$route.meta.previousRoute ? true : false
    },
    methods: {
      handleClick() {
        if (this.$route.meta.previousRoute)
          this.$router.push({ name: this.$route.meta.previousRoute.link})
      },
      closeMenu() {
        this.toggled = false;
      },
     
      handleImageLoad(event) {
      if (event?.target?.src?.includes('blob'))
        URL.revokeObjectURL(event?.target?.src);
    },
    },
  computed: {
    previousRouteLabel() {
      return this.$route.meta.previousRoute ? this.$route.meta.previousRoute.label : ''
    }
  }
  };
</script>
<style></style>
